import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Andrew Amgad', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: "Andrew Amgad's Portfolio", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hi, my name is",
  name: 'Andrew Amgad',
  subtitle: "I'm a Full-Stack Web Developer",
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpeg',
  paragraphOne: "I'm a Full-Stack Web Developer and coding enthusiast. I have designed and developed many scalable and maintainable web applications and APIs.",
  paragraphTwo: 'I use MERN with TypeScript as my main development stack. My skill set also includes NextJS, React Native, GraphQL and many more!',
  paragraphThree: " ",
  // resume: 'https://drive.google.com/file/d/1IEkhbtqofCz_95BwKgweQRhu9RIxuIKO/view?usp=sharing',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'hashtaghero',
    title: 'HashtagHero',
    info: 'HashtagHero allows you to group and manage all of your social media accounts with ease.',
    info2: 'You can schedule posts to be published across different accounts at the same time, engage with your audience through comments and private messages, view audience analytics, all in one place!',
    url: 'https://hashtaghero.io'
  },

  {
    id: nanoid(),
    img: 'ourstory.png',
    title: 'OurStory: Vent & Support',
    info: 'Vent, talk, get help or support people in need. OurStory is a mobile application that gives you a voice and a platform to talk about your deepest secrets or problems anonymously, while being offered guidance and support from people with similar experiences.',
    info2: "I took on the role of designing, building and deploying a RESTful API for the application using NodeJS, Express & MongoDB.",
    url: 'https://ourstory.andrewamgad.com',
    repo: 'https://github.com/AndrewAmgad/ourstory-api', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'inscribed2.png',
    title: 'Inscribed',
    info: "Inscribed was my very first production-ready web application & API that I've built from scratch using the MERN stack.",
    info2: 'It is a note-taking application which serves the purpose of writing down notes & lists with additional features such as folder separation, note coloring and pinning.',
    url: 'https://inscribed.andrewamgad.com/',
    repo: 'https://github.com/AndrewAmgad/Inscribed', // if no repo, the button will not show up
  },
  

  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '/',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: "Would you like to work with me?",
  btn: "Let's Talk",
  email: 'contact@andrewamgad.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/andrewamgad',
    },
    {
      id: nanoid(),
      name: 'upwork',
      url: 'https://www.upwork.com/fl/andrewmouris'
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/AndrewAmgad',
    },

  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
